import { Component } from '@angular/core';
import { NavController,Platform,LoadingController,IonSlides,ToastController,ModalController,NavParams } from '@ionic/angular';
import { ServerService } from '../service/server.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-add',
  templateUrl: 'product-add.page.html',
  styleUrls: ['product-add.page.scss'],
})
export class ProductAddPage {

text:any;
name:any;
price:any;
data:any;
id:any;

constructor(public navParams: NavParams,public loadingController: LoadingController,public server : ServerService,private route: ActivatedRoute,public nav : NavController,public toastController: ToastController,public modalController: ModalController) {

this.text    = JSON.parse(localStorage.getItem('app_text'));
this.data    = navParams.get('data');

if(this.data && this.data.id)
{
  this.name       = this.data.name;
  this.price      = this.data.price;
  this.id         = this.data.id;
}

}

  ngOnInit()
  {
    
  }

  async saveData(data)
  {
    await this.modalController.dismiss({id : this.id,name:data.name,price:data.price});
  }

  async close()
  {
    await this.modalController.dismiss({});
  }

  async presentToast(txt) {
    const toast = await this.toastController.create({
      message: txt,
      duration: 3000,
      position : 'top',
      mode:'ios',
      color:'dark'
    });
    toast.present();
  }
}
