import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServerService } from '../service/server.service';
import { NavParams,ToastController,NavController,Platform,LoadingController,AlertController,ModalController } from '@ionic/angular';
import { PayPal, PayPalPayment, PayPalConfiguration } from '@ionic-native/paypal/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';

declare var RazorpayCheckout: any;


@Component({
  selector: 'app-chooseplan',
  templateUrl: './chooseplan.page.html',
  styleUrls: ['./chooseplan.page.scss'],
})

export class ChooseplanPage implements OnInit {
  
@ViewChild('content',{static : false}) private content: any;


  data:any;
  payment_method:any;
  setting:any;
  user:any;
  stripeView = false;
  card_no:any;
  exp_month:any;
  exp_year:any;
  cvv:any;
  year:any;
  text:any;
  constructor(private stripe: Stripe,private payPal: PayPal,public navParams: NavParams,public modalController: ModalController,public alertController: AlertController,private route: ActivatedRoute,public server : ServerService,public toastController: ToastController,private nav: NavController,public loadingController: LoadingController,private iab: InAppBrowser){

  this.data      = navParams.get('plan');
  this.setting   = JSON.parse(localStorage.getItem('setting'));
  this.user      = JSON.parse(localStorage.getItem('user_data'));
  this.text      = JSON.parse(localStorage.getItem('app_text'));
  this.year      = JSON.parse(localStorage.getItem('year'));

  }

  ngOnInit()
  {
  }

  setPaymentMethod(id)
  {
    this.payment_method = id;

    if(id == 3)
    {
      this.stripeView = true;

      setTimeout(() => {
      this.content.scrollToBottom(300);
      }, 100);
    }
  }

  async closeModal() {
    
    await this.modalController.dismiss({data:true});
  }

  async buyplan()
  {
    const loading = await this.loadingController.create({
      message: '',
      spinner : 'bubbles',
      duration:3000
    });
    await loading.present();

    this.server.buyplan(this.data.id,this.payment_method).subscribe((response:any) => {

    this.presentToast(this.text.plan_success);

    this.closeModal();
   
    loading.dismiss();

    });
  }


  makePayment()
  {
    if(this.payment_method == 1)
    {
      this.paypalPayment();
    }
    else if(this.payment_method == 2)
    {
      this.payWithRazor();
    }
    else if(this.payment_method == 3)
    {
      this.payWithStripe();
    }
    else if(this.payment_method == 4)
    {
      this.payStack();
    }
    else
    {
     return this.presentToast(this.text.payment_error); 
    }
  }


  /********************************************************
  |Pay with Paypal
  |********************************************************/

  paypalPayment()
  {
    this.payPal.init({
      PayPalEnvironmentProduction: this.setting.paypal_key,
      PayPalEnvironmentSandbox: this.setting.paypal_key
    }).then(() => {
      // Environments: PayPalEnvironmentNoNetwork, PayPalEnvironmentSandbox, PayPalEnvironmentProduction
      this.payPal.prepareToRender('PayPalEnvironmentSandbox', new PayPalConfiguration({
        // Only needed if you get an "Internal Service Error" after PayPal login!
        //payPalShippingAddressOption: 2 // PayPalShippingAddressOptionPayPal
      })).then(() => {
        let payment = new PayPalPayment(this.data.price, this.data.code, 'Easy Invoice Subscription Plan', 'sale');
        this.payPal.renderSinglePaymentUI(payment).then(() => {
          
        this.buyplan();


        }, () => {
          
        this.presentToast("Something went wrong.");

        });
      }, () => {
        
      this.presentToast("Something went wrong.");

      });
    }, () => {
      
      this.presentToast("Something went wrong.");


    });
  }

  /********************************************************
  |Pay with RazorPay
  |********************************************************/

  payWithRazor() {
    var options = {
      description: 'Pay Now',
      image: 'https://cdn.iconscout.com/icon/free/png-512/bhim-3-69845.png',
      currency: this.data.code,
      key: this.setting.razor_key,
      amount: this.data.price * 100,
      name: 'Subscription Plan',
      prefill: {
        email: this.user.email,
        contact: this.user.phone,
        name: this.user.com_name
      },
      theme: {
        color: '#2196f3'
      },
      modal: {
        ondismiss: function () {
          alert('dismissed')
        }
      }
    };

    var successCallback = (success)=> {
      
      this.buyplan();

    };

    var cancelCallback = function (error) {
      alert(error.description + ' (Error ' + error.code + ')');
    };

    RazorpayCheckout.open(options, successCallback, cancelCallback);
  }

  /********************************************************
  |Pay with Stripe
  |********************************************************/

  payWithStripe()
  {    
    var cNo = this.card_no;

    if(cNo && cNo.length == 16 && this.exp_month.length == 2 && this.exp_year.length == 4 && this.cvv.length == 3)
    {
        this.stripe.setPublishableKey(this.setting.stripe_key);

        let card = {
         number: cNo,
         expMonth: this.exp_month,
         expYear: this.exp_year,
         cvc: this.cvv
        }

        var cardNo        = false;
        var cvvCorrect    = false;

        //validate card no
        this.stripe.validateCardNumber(cNo)
          .then(res => {
            
          
          this.stripe.createCardToken(card)
            .then(token => {
              
              if(token.id)
              {
                this.makePaymentSrtipe(token.id,cNo);
              }
              else
              {
                this.presentToast("Please enter valid card details.");
              }

            })
            .catch(error => {

            this.presentToast("Something went wrong");

            });


          })
          .catch(error => {

          this.presentToast("Something went wrong.Please check card details.");

          });
    }
    else
    {
      this.presentToast("Something went wrong");
    }
  }

  async makePaymentSrtipe(token,cNo)
  {
    const loading = await this.loadingController.create({
      spinner: 'bubbles'
    });
    await loading.present();

    this.server.makeStripePayment("?token="+token+"&amount="+this.data.price+"&user_id="+this.user.id).subscribe((response:any) => {

    if(response.data == "done")
    {
        this.buyplan();
    }
    else
    {
      this.presentToast(response.error);
    }

    loading.dismiss();

    });
  }


  /********************************************************
  |Pay with PayStack
  |********************************************************/

payStack()
{
    var apiUrl     = this.server.getApiUrl();
    var query:any  = "?name="+this.user.com_name+"&phone="+this.user.phone+"&email="+this.user.email+"&amount="+this.data.price;
    var paymetUrl  = apiUrl+"payStackApi"+query;
    var successUrl = apiUrl+"payStackSuccess";
    var failUrl    = apiUrl+"payStackCancel"

    const browser = this.iab.create(paymetUrl,"_blank",{zoom : 'no',location : 'no',toolbar :'no'});

    browser.on('loadstop').subscribe(event => {
       
    if(event.url === successUrl)
    {
       this.buyplan();

       browser.close();

    }
    else if(event.url === failUrl)
    {
       this.presentToast("Payment Cancelled");

       browser.close();   
    }

    });

    browser.show();
}

  /********************************************************
  |Pay with FlutterWave
  |********************************************************/  
  

  async presentToast(txt) {
    const toast = await this.toastController.create({
      message: txt,
      duration: 3000,
      position : 'bottom',
      mode:'ios',
      color:'dark'
    });
    toast.present();
  }

  allSet()
  {
    if(this.payment_method)
    {
      if(this.payment_method == 3)
      {
        if(this.card_no && this.exp_month && this.exp_year && this.cvv)
        {
          return true;
        }
        else
        {
          return false;
        }
      }
      else
      {
        return true;
      }
    }
    else
    {
      return false;
    }
  }
}
