import { Component } from '@angular/core';
import { NavController,Platform,LoadingController,IonSlides,ToastController,ModalController,NavParams } from '@ionic/angular';
import { ServerService } from '../service/server.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-client-add',
  templateUrl: 'client-add.page.html',
  styleUrls: ['client-add.page.scss'],
})
export class ClientAddPage {

text:any;
name:any;
phone:any;
email:any;
address:any;
data:any;
id:any;

constructor(public navParams: NavParams,public loadingController: LoadingController,public server : ServerService,private route: ActivatedRoute,public nav : NavController,public toastController: ToastController,public modalController: ModalController) {

this.text    = JSON.parse(localStorage.getItem('app_text'));
this.data    = navParams.get('data');

if(this.data && this.data.id)
{
  this.name       = this.data.name;
  this.phone      = this.data.phone;
  this.email      = this.data.email;
  this.address    = this.data.address;
  this.id         = this.data.id;
}

}

  ngOnInit()
  {
    
  }

  async saveData(data)
  {
    await this.modalController.dismiss({id : this.id,name:data.name,phone:data.phone,email:data.email,address:data.address});
  }

  async close()
  {
    await this.modalController.dismiss({});
  }

  async presentToast(txt) {
    const toast = await this.toastController.create({
      message: txt,
      duration: 3000,
      position : 'top',
      mode:'ios',
      color:'dark'
    });
    toast.present();
  }
}
