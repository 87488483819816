import { Component, OnInit } from '@angular/core';

import { Platform,NavController } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/main', icon: 'chevron-back' },
    { title: 'List of Coustomers', url: '/clist', icon: 'chevron-back' },
    { title: 'Account Page', url: '/account', icon: 'chevron-back' },
    { title: 'Legals', url: '/legal', icon: 'chevron-back' },
   
  ];
  setting:any;
  text:any;
  user:any;
  dark_mode = false;
  dir = 'ltr';
  lang_data:any;

  constructor(

    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private nav: NavController,
    private oneSignal: OneSignal

  ) {


  if(localStorage.getItem('dark_mode') == '1')
  {
   this.dark_mode = true;
  }
  else
  {
     this.dark_mode = false;
  }

  if(localStorage.getItem('dark_mode') == '1')
  {
     document.body.setAttribute('color-theme','dark');
  }
  else
  {
       document.body.setAttribute('color-theme','light');
  }

  this.initializeApp();

  if(localStorage.getItem('user_data') && localStorage.getItem('user_data') != undefined)
  {
      this.user = JSON.parse(localStorage.getItem('user_data'));
  }

  if(localStorage.getItem('setting') && localStorage.getItem('setting') != undefined)
  {
      this.setting = JSON.parse(localStorage.getItem('setting'));
  }

  if(localStorage.getItem('app_text') && localStorage.getItem('app_text') != undefined)
  {
      this.text = JSON.parse(localStorage.getItem('app_text'));
  }

  if(localStorage.getItem('user_id') && localStorage.getItem('user_id') != 'null')
  {
    this.nav.navigateRoot('/main');
  }
  else
  {
    this.nav.navigateRoot('/welcome');
  }

  if(localStorage.getItem('lang_data') && localStorage.getItem('lang_data') != "undefined")
  {
      this.lang_data = JSON.parse(localStorage.getItem('lang_data'));

      this.dir       = this.lang_data.type == '1' ? 'rtl' : 'ltr';
  }

  }

  toggleTheme(event)
  {
    if(event.detail.checked == true)
    {
      document.body.setAttribute('color-theme','dark');
      localStorage.setItem('dark_mode','1');

      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#000000');
      this.statusBar.styleDefault();
      this.statusBar.styleLightContent();


    }else{
      document.body.setAttribute('color-theme','light');
      localStorage.setItem('dark_mode','0');

      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#f4f5f8');
      this.statusBar.styleDefault();
    }
  }

  initializeApp() {
  this.platform.ready().then(() => {

    if(this.setting && this.setting.id)
    {
      this.sub();
    }

    this.statusBar.styleDefault();
    //this.splashScreen.hide();
    
    if(localStorage.getItem('dark_mode') == '1')
    {
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#000000');
      this.statusBar.styleDefault();
      this.statusBar.styleLightContent();
      

    }
    else
    {
       this.statusBar.overlaysWebView(false);
       this.statusBar.backgroundColorByHexString('#f4f5f8');
       this.statusBar.styleDefault();
    }

  });
  }

  sub()
  {
    this.oneSignal.startInit(this.setting.push_app_id, this.setting.push_google);

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

    this.oneSignal.handleNotificationReceived().subscribe(() => {
     // do something when notification is received
    });

    this.oneSignal.handleNotificationOpened().subscribe(() => {
      // do something when a notification is opened
    });


    if(localStorage.getItem('user_id') && localStorage.getItem('user_id') != undefined)
    {
          this.oneSignal.sendTags({user_id: localStorage.getItem('user_id')});
    }

    this.oneSignal.endInit();
  }

  logout()
  {
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_data");

    this.nav.navigateRoot('/login');
  }
}
